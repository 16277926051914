import * as React from 'react'
import Layout from '../components/layout'
import PlatformHeader from '@/components/PlatformPage/PlatformHeader'
import PlatformAboutFortress from '@/components/PlatformPage/PlatformAboutFortress'
import PlatformServices from '@/components/PlatformPage/PlatformServices'
import PlatformFaq from '@/components/PlatformPage/PlatformFaq'
import PlatformSolutions from '@/components/PlatformPage/PlatformSolutions'
import { Footer } from '@/components/Footer'
import PlatformCTA from '@/components/PlatformPage/PlatformCTA'
import { graphql, useStaticQuery } from 'gatsby'

const Platform: React.FC = () => {
  const {
    allContentfulSubPagesTitles: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSubPagesTitles {
        nodes {
          platformPage
        }
      }
    }
  `)

  const { platformPage } = nodes[0]
  return (
    <Layout title={platformPage}>
      <PlatformHeader />
      <PlatformAboutFortress />
      <PlatformServices />
      <PlatformFaq />
      <PlatformSolutions />
      <PlatformCTA />
      <Footer />
    </Layout>
  )
}

export default Platform
