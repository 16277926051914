import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import richTextOptions from '@/utils/richTextOptions'
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image'
import ContentContainer from '@/components/UI/ContentContainer'

const Wrapper = styled(ContentContainer)`
  background-color: ${({ theme }) => theme.colors.lightGray};
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.semiTitle};
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.rwd.mobile.maxWidth}) {
    font-size: ${({ theme }) => theme.fontSize.s25};
  }
`

const Text = styled.div`
  line-height: 24px;
  max-width: 600px;

  p {
    margin-bottom: 20px;
  }
`
const Content = styled.div<{ reverse?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ reverse }) =>
    reverse ? 'column-reverse' : 'column'};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
    flex-direction: row;
    text-align: left;
  }
`

const StyledImage = styled.div<{ reverse?: boolean }>`
  img {
    max-width: 500px !important;

    @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
      max-width: 603px !important;
      min-width: 500px !important;
    }
  }
  @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
    margin-inline: ${({ reverse }) => (reverse ? '10% 0' : '0 10%')};
  }
`

const PlatformAboutFortress: React.FC = () => {
  const {
    allContentfulPlatformAboutFortress: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulPlatformAboutFortress {
        nodes {
          image1 {
            gatsbyImageData(quality: 100)
          }
          image2 {
            gatsbyImageData(quality: 100)
          }
          image3 {
            gatsbyImageData(quality: 100)
          }
          text1 {
            raw
          }
          text2 {
            raw
          }
          text3 {
            raw
          }
          title
        }
      }
    }
  `)

  const { title, text1, text2, text3, image1, image2, image3 } = nodes[0]

  const mockedImage1 = getImage(image1)
  const mockedImage2 = getImage(image2)
  const mockedImage3 = getImage(image3)

  const richText1 = renderRichText(text1, richTextOptions)
  const richText2 = renderRichText(text2, richTextOptions)
  const richText3 = renderRichText(text3, richTextOptions)

  return (
    <Wrapper>
      <Title
        data-sal="slide-down"
        data-sal-duration="1000"
        data-sal-easing="easeOutQuad"
      >
        {title}
      </Title>

      <Content reverse>
        <Text
          data-sal="zoom-in"
          data-sal-duration="1000"
          data-sal-easing="easeOutQuad"
        >
          {richText1}
        </Text>
        <StyledImage
          reverse
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="easeOutQuad"
        >
          <GatsbyImage
            image={mockedImage1 as IGatsbyImageData}
            alt="mockedImage"
          />
        </StyledImage>
      </Content>
      <Content>
        <StyledImage
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="easeOutQuad"
        >
          <GatsbyImage
            image={mockedImage2 as IGatsbyImageData}
            alt="mockedImage"
          />
        </StyledImage>
        <Text
          data-sal="zoom-in"
          data-sal-duration="1000"
          data-sal-easing="easeOutQuad"
        >
          {richText2}
        </Text>
      </Content>
      <Content reverse>
        <Text
          data-sal="zoom-in"
          data-sal-duration="1000"
          data-sal-easing="easeOutQuad"
        >
          {richText3}
        </Text>
        <StyledImage
          reverse
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="easeOutQuad"
        >
          <GatsbyImage
            image={mockedImage3 as IGatsbyImageData}
            alt="mockedImage"
          />
        </StyledImage>
      </Content>
    </Wrapper>
  )
}

export default PlatformAboutFortress
