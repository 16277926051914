import React from 'react'
import styled from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import ContentContainer from '@/components/UI/ContentContainer'

type CasesSectionGraphqlData = {
  icon: { file: { url: string } }
  text: string
  link: string
}

const PlatformSolutions: React.FC = () => {
  const {
    contentfulLearnMoreAboutFortressSection: { title, list },
  } = useStaticQuery(graphql`
    {
      contentfulLearnMoreAboutFortressSection {
        title
        list {
          icon {
            file {
              url
            }
          }
          text
          link
        }
      }
    }
  `)

  const cards = list.map((item: CasesSectionGraphqlData, key: number) => (
    <Section
      data-sal="slide-down"
      data-sal-duration="1000"
      data-sal-delay={`${100 * key}`}
      data-sal-easing="easeOutQuad"
    >
      <Link to={item.link}>
        <Card>
          <img src={item.icon.file.url} alt="icon" />
        </Card>
      </Link>
      <p>{item.text}</p>
    </Section>
  ))

  return (
    <Wrapper>
      <Title
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="100"
        data-sal-easing="easeOutQuad"
      >
        {title}
      </Title>
      <Cards>{cards}</Cards>
    </Wrapper>
  )
}

const Wrapper = styled(ContentContainer)`
  background-color: ${({ theme }) => theme.colors.lightGray};
`

const Title = styled.div`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.s25};
  margin-bottom: 20px;
`
const Cards = styled.div`
  justify-content: center;
  flex-flow: wrap;
  display: flex;
`

const Section = styled.div`
  text-align: center;
  margin-top: 40px;
  margin-inline: 30px;
  p {
    font-family: ${({ theme }) => theme.fonts.semiBold};
    font-size: ${({ theme }) => theme.fontSize.s22};

    margin-top: 20px;
  }

  width: 250px;
`

const Card = styled.div`
  img {
    max-width: 100px;

    @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
      max-width: 150px;
    }
  }
  cursor: pointer;
  height: 150px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    height: 250px;
  }

  transform: scale(1);
  transition: transform 0.2s;
  :hover {
    transform: scale(1.05);
  }

  box-shadow: rgb(198, 198, 198) 0px 0px 11px 0px;
  background-color: white;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
`

export default PlatformSolutions
