import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import styled from 'styled-components'
import Arrow from '@/assets/blueArrow.svg'
import ContentContainer from '@/components/UI/ContentContainer'
import SemiTitle from '@/components/UI/SemiTitle'
import { StaticImage } from 'gatsby-plugin-image'

const Wrapper = styled(ContentContainer)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
`

const Header = styled.div``

const Title = styled(SemiTitle)`
  color: white;
  text-align: center;
  margin-block: 40px;
`

const Card = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  max-width: 800px;
  padding: 20px 20px 50px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 22px 0 rgba(41, 40, 73, 0.12);
  background-color: white;
  margin-bottom: 20px;
  text-align: center;
  

  @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
    text-align: left;
    padding: 50px;
  }
  
  span.question {
    font-family: ${({ theme }) => theme.fonts.semiBold};
  }

  button {
    height: fit-content;
    padding: 0;
    position: absolute;
    bottom: 20px;
    margin: auto;
    left: 0;
    right: 0;


    @media (min-width: ${({ theme }) => theme.rwd.tablet.s}) {
      left: unset;
      margin: unset;
      right: 25px;
      top: 56px;
    }
    

    img {
      display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
    }

    hr {
      display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
      border: 1px solid ${({ theme }) => theme.colors.blue};
      width: 16px;
      margin-right: 3px;
    }
  
`
const Cards = styled.div``

const ExtraContent = styled.div<{ height: string; isOpen: boolean }>`
  max-height: ${({ height }) => height};
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: white;

  margin: 0 auto;
`

const PlatformFaq: React.FC = () => {
  const {
    contentfulFaqSection: { title, faqList },
    backgroundImage,
  } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "Platform/faqBg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      contentfulFaqSection {
        title
        faqList {
          id
          question
          answer {
            answer
          }
        }
      }
    }
  `)

  const [y, setY] = useState<number | undefined>(0)
  useEffect(() => {
    const elmnt = document.querySelector('.extra-content')
    const y = elmnt?.scrollHeight
    setY(y)
  }, [])

  const cards = faqList.map(
    (
      item: { question: string; answer: { answer: string }; id: string },
      key: number
    ) => {
      const [isOpen, setIsOpen] = useState(false)
      const toggle = () => {
        setIsOpen(!isOpen)
      }

      return (
        <Card key={key} isOpen={isOpen}>
          <div>
            <span className="question">{item.question}</span>
            <button onClick={toggle}>
              <img src={Arrow} alt={'icon'} />
              <hr />
            </button>
          </div>

          <ExtraContent
            isOpen={isOpen}
            height={isOpen ? `${y && y + 200}px` : '0px'}
            className="extra-content"
          >
            {item.answer?.answer}
          </ExtraContent>
        </Card>
      )
    }
  )

  return (
    <BackgroundImageWrapper image={backgroundImage.childImageSharp.fluid}>
      <Wrapper>
        <Header>
          <StaticImage
            width={233}
            src={'../../assets/Platform/faqIcon.png'}
            alt={'faqIcon'}
          />
          <Title>{title}</Title>
        </Header>
        <Cards>{cards}</Cards>
      </Wrapper>
    </BackgroundImageWrapper>
  )
}

export default PlatformFaq
