import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import towerImage from '@/assets/Platform/servicesTower.png'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import richTextOptions from '@/utils/richTextOptions'
import ContentContainer from '@/components/UI/ContentContainer'
import SemiTitle from '@/components/UI/SemiTitle'

const Wrapper = styled(ContentContainer)`
  z-index: 1;
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightGray};
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.rwd.desktop.xxl}) {
    padding: 2% 0;
  }
`

const Title = styled(SemiTitle)`
  text-align: center;

  margin-bottom: 10px;
`

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.rwd.desktop.xxl}) {
    justify-content: unset;
  }
`

const Card = styled.div`
  max-width: 400px;
  min-height: 350px;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 22px 0 rgba(41, 40, 73, 0.12);
  margin-inline: 15px;
  margin-bottom: 30px;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.rwd.mobile.s}) {
    flex-direction: row;
  }

  @media (min-width: ${({ theme }) => theme.rwd.mobile.m}) {
    text-align: left;
  }
`
const Container = styled.div`
  margin: auto;

  @media (min-width: ${({ theme }) => theme.rwd.desktop.xxl}) {
    width: 68%;
  }
`
const TowerImage = styled.div`
  z-index: -1;
  position: absolute;
  bottom: 0;
  display: none;

  @media (min-width: ${({ theme }) => theme.rwd.desktop.xl}) {
    right: 70px;
    display: flex;
  }

  @media (min-width: ${({ theme }) => theme.rwd.desktop.xxl}) {
    right: 391px;
    display: flex;
  }
`
const CardTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.s20};
  position: relative;
  margin-left: 10px;

  @media (min-width: ${({ theme }) => theme.rwd.mobile.m}) {
    top: 25px;
  }
`

const TitleImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.rwd.mobile.m}) {
    flex-direction: row;
  }
`

const PlatformServices: React.FC = () => {
  const {
    allContentfulServicesSection: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulServicesSection {
        nodes {
          text
          title
          serivicesList {
            title
            text {
              raw
            }
            icon {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { text, title, serivicesList } = nodes[0]

  const Cards = serivicesList.map((item: any, key: any) => {
    const richText = renderRichText(item.text, richTextOptions)

    return (
      <Card>
        <TitleImageWrapper>
          <img
            width={93}
            height={93}
            src={item.icon.file.url}
            alt={'icon'}
          />
          <CardTitle>{item.title}</CardTitle>
        </TitleImageWrapper>
        <div style={{ marginTop: 20, lineHeight: 1.38 }}>{richText}</div>
      </Card>
    )
  })

  return (
    <Wrapper>
      <Title>{title}</Title>
      <p style={{ textAlign: 'center' }}>{text}</p>

      <Container>
        <CardsWrapper>{Cards}</CardsWrapper>
      </Container>
      <TowerImage>
        <img width={243} height={345} src={towerImage} alt="tower" />
      </TowerImage>
    </Wrapper>
  )
}

export default PlatformServices
