import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button } from '../StyledComponents/Button'
import { graphql, useStaticQuery } from 'gatsby'
import { ModalContext } from '@/components/layout'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import ContentContainer from '@/components/UI/ContentContainer'
import { useWindowSize } from '@/hooks'

const Wrapper = styled(ContentContainer)`
  display: flex;
  align-items: center;
  height: 260px;

  @media (min-width: ${({ theme }) => theme.rwd.tablet.m}) {
    height: 400px;
  }

  text-align: center;
  position: relative;
`
const TextWrapper = styled.div`
  text-align: center;
  margin-inline: auto;
`
const Title = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.s25};
  margin-bottom: 20px;
`

const StyledButton = styled(Button)`
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blue};
  :hover {
    background-color: #dfecff;
    color: #0043a7;
  }
`

const PlatformCTA: React.FC = () => {
  const {
    allContentfulCtaSection: { nodes },
    backgroundImage,
    backgroundImageMobile,
  } = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "Platform/ctaBg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      backgroundImageMobile: file(
        relativePath: { eq: "CtaBlueBgMobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allContentfulCtaSection {
        nodes {
          button {
            text
          }
          title
        }
      }
    }
  `)
  const toggleModal = useContext(ModalContext)
  const { width } = useWindowSize()
  const resultBg =
    width < 1200
      ? backgroundImageMobile.childImageSharp.fluid
      : backgroundImage.childImageSharp.fluid

  const { title, button } = nodes[0]

  return (
    <BackgroundImageWrapper image={resultBg}>
      <Wrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <StyledButton onClick={toggleModal as () => void} primary>
            {button.text}
          </StyledButton>
        </TextWrapper>
      </Wrapper>
    </BackgroundImageWrapper>
  )
}

export default PlatformCTA
