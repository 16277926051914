import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImageWrapper from '@/components/UI/BackgroundImageWrapper'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import richTextOptions from '@/utils/richTextOptions'
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image'
import ContentContainer from '@/components/UI/ContentContainer'
import SemiTitle from '@/components/UI/SemiTitle'

const Wrapper = styled(ContentContainer)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
    flex-direction: row;
    text-align: left;
  }
`

const Title = styled(SemiTitle)`
  margin-bottom: 40px;
`

const Text = styled.div`
  line-height: 24px;
  max-width: 600px;

  p {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 20px;
  }
`

const StyledImage = styled.div<{ reverse?: boolean }>`
  img {
    max-width: 500px !important;

    @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
      max-width: 603px !important;
      min-width: 500px !important;
    }
  }
  @media (min-width: ${({ theme }) => theme.rwd.desktop.m}) {
    margin-inline: 10% 0;
  }
`

const PlatformHeader: React.FC = () => {
  const {
    allContentfulPlatformHeader: { nodes },
    backgroundImage,
  } = useStaticQuery(graphql`
    {
      backgroundImage: file(
        relativePath: { eq: "Platform/platformHeader-Bg.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      allContentfulPlatformHeader {
        nodes {
          image {
            gatsbyImageData(quality: 100)
          }
          title
          text {
            raw
          }
        }
      }
    }
  `)

  const { title, text, image } = nodes[0]
  const mockedImage = getImage(image)

  const richText = renderRichText(text, richTextOptions)

  return (
    <>
      <BackgroundImageWrapper
        styles={{ minHeight: 522 }}
        image={backgroundImage.childImageSharp.fluid}
      >
        <Wrapper>
          <div>
            <Title
              data-sal="slide-down"
              data-sal-duration="1000"
              data-sal-easing="easeOutQuad"
            >
              {title}
            </Title>
            <Text
              data-sal="zoom-in"
              data-sal-duration="1000"
              data-sal-easing="easeOutQuad"
            >
              {richText}
            </Text>
          </div>

          <StyledImage
            data-sal="fade"
            data-sal-duration="1000"
            data-sal-easing="easeOutQuad"
          >
            <GatsbyImage
              image={mockedImage as IGatsbyImageData}
              alt="mockedImage"
            />
          </StyledImage>
        </Wrapper>
      </BackgroundImageWrapper>
    </>
  )
}

export default PlatformHeader
